<template>
  <div class="ecommerce-application">
    <div class="list-view">
      <b-row v-if="!cart || !cart.lineas || !cart.lineas.length">
        <b-col cols="12">
          <b-card>
            <div class="text-center my-3">
              <h1 class="mb-2">{{ $i18n.messages.CarritoVacio }}</h1>
              <router-link :to="{ name: 'products' }">
                <b-button variant="primary" size="lg">
                  {{ $i18n.messages.VerProductos }}
                </b-button>
              </router-link>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="cart && cart.lineas && cart.lineas.length">
        <b-col cols="12">
          <div class="checkout-items">
            <b-card
              v-for="linea in cart.lineas"
              :key="linea.idLinea"
              class="ecommerce-card"
              no-body
            >
              <!-- Product Details: Card Body -->
              <b-card-body>
                <router-link
                  :to="{ name: 'product', params: { id: linea.codArt } }"
                >
                  <div class="item-name">
                    <h6 class="mb-0">
                      <span class="text-body">
                        {{ linea.articulo }}
                      </span>
                    </h6>
                    <span class="item-company"
                      >{{ linea.codArt }} - {{ linea.idArticulo }}</span
                    >
                  </div>
                  <p class="mt-1">
                    <b-badge
                      pill
                      :variant="linea.pickingLine ? 'danger' : 'light'"
                    >
                      <span v-if="linea.pickingLine">{{
                        $i18n.messages.ConRecargoPicking
                      }}</span>
                      <span v-else>{{ $i18n.messages.SinRecargoPicking }}</span>
                    </b-badge>
                  </p>
                  <p>
                    {{ $i18n.messages.PorCajaM2 }}:
                    {{
                      Number(linea.metrosCaja).toLocaleString("de-DE", {
                        maximumFractionDigits: 4,
                      })
                    }}
                  </p>
                  <p>
                    {{ $i18n.messages.Pales }} (1 {{ $i18n.messages.Pale }} =
                    {{ linea.cajasPalet }} {{ $i18n.messages.Cajas }})
                  </p>
                  <p>
                    {{ $i18n.messages.SuperficieM2 }}:
                    {{
                      Number(linea.cantidad * linea.metrosCaja).toLocaleString(
                        "de-DE",
                        {
                          maximumFractionDigits: 4,
                        }
                      )
                    }}
                  </p>
                  <p>
                    {{ $i18n.messages.weight }}:
                    {{
                      Number(linea.peso).toLocaleString("de-DE", {
                        maximumFractionDigits: 4,
                      })
                    }}
                  </p>
                </router-link>
              </b-card-body>

              <!-- Product Options/Actions -->
              <div class="item-options text-center">
                <div class="item-wrapper">
                  <div class="item-cost">
                    <h4 class="item-price">
                      {{ $i18n.messages.totalCajas }}: {{ linea.cantidad }}
                    </h4>
                  </div>
                </div>
                <b-button
                  variant="primary"
                  class="mt-1"
                  @click="openModalProduct(linea)"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>{{ $i18n.messages.Actualizar }}</span>
                </b-button>
                <b-button
                  variant="danger"
                  class="mt-1 remove-wishlist"
                  @click="removeProductFromCartClick(linea)"
                >
                  <feather-icon icon="XIcon" class="mr-50" />
                  <span>{{ $i18n.messages.Eliminar }}</span>
                </b-button>
              </div>
            </b-card>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="checkout-options">
            <b-card>
              <label for="comment">{{ $i18n.messages.FechaText }}</label>
              <b-form-datepicker
                id="example-datepicker"
                v-model="fecha"
                :min="min"
                start-weekday="1"
                :value-as-date="true"
                :label-no-date-selected="$i18n.messages.SelectDate"
                label-help=""
                selected-variant="foreground"
              ></b-form-datepicker>

              <!--   <p>Value: '{{ format_date(fecha) }}'</p> -->
              <label for="comment">{{ $i18n.messages.Comentario }}</label>
              <b-form-textarea
                id="comment"
                :placeholder="$i18n.messages.ComentarioPlaceholder"
                rows="3"
                v-model="comment"
              />

              <b-button
                variant="light"
                size="lg"
                class="w-100 mt-2"
                @click="compruebaProductos()"
                :disabled="disableBtn || fecha == null || fecha == ''"
              >
                <b-spinner v-if="disableBtn" small></b-spinner>
                {{ $i18n.messages.ENVIARPEDIDO }}
              </b-button>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </div>
    <modal-edit-product
      :product="productAdd"
      :quantityPales="quantityPales"
      :quantityCajas="quantityCajas"
      :idCarrito="idCarrito"
      :idLinea="idLinea"
      v-if="productAdd && Object.keys(productAdd).length"
    />
    <modal-stock-available></modal-stock-available>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BCardBody,
  BFormTextarea,
  BBadge,
  BSpinner,
  BCalendar,
  BFormDatepicker,
} from "bootstrap-vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ModalEditProduct from "@/views/ModalEditProduct.vue";
import moment from "moment";
import ModalStockAvailable from "@/views/ModalStockAvailable.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormTextarea,
    BCardBody,
    BBadge,
    BSpinner,
    ModalEditProduct,
    BCalendar,
    BFormDatepicker,
    ModalStockAvailable,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);
    return {
      modal: true,
      comment: "",
      quantityPales: 0,
      quantityCajas: 0,
      disableBtn: false,
      fecha: "",
      min: minDate,
    };
  },
  created() {
    if (this.currentCustomer) {
      console.log("this.currentCustomer: ", this.currentCustomer);
      this.setLoading(true);
      this.getCartOrCreate({ username: "", idClient: "" }).then(() => {
        this.setLoading(false);
      });
    }
  },
  computed: {
    ...mapGetters({
      cart: "carts/getCart",
      productAdd: "products/getProductAdd",
      currentCustomer: "customers/getCurrentCustomer",
    }),
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYYMMDD");
      }
    },
    ...mapActions({
      getCartOrCreate: "carts/getCartOrCreate",
      createOrder: "orders/createOrder",
      // checkProducts: "orders/checkProducts",
      removeLine: "carts/removeLine",
      updateLine: "carts/updateLine",
      getProductAdd: "products/getProductAdd",
    }),
    ...mapMutations({
      setShowModalAdd: "products/setShowModalAdd",
      setShowModalStockAvailable: "orders/setShowModalStockAvailable",
      setLoading: "app/setLoading",
    }),
    removeProductFromCartClick(linea) {
      this.removeLine(linea.idLinea);
    },
    openModalProduct(linea) {
      this.quantityPales = Math.floor(linea.cantidad / linea.cajasPalet);
      this.quantityCajas = linea.cantidad % linea.cajasPalet;
      this.idCarrito = this.cart.idCarrito;
      this.idLinea = linea.idLinea;
      this.getProductAdd(linea.codArt);
      this.setShowModalAdd(true);
    },
    async compruebaProductos() {
      var setShowModalStockAvailable = this.setShowModalStockAvailable;
      // this.disableBtn = true;
      var fechaString = this.format_date(this.fecha);

      var obj = {
        comment: this.comment,
        fecha: fechaString,
        setShowModalStockAvailable,
      };
      console.log(obj);
      this.setLoading(true);
      await this.createOrder(obj);
    },
    /*     creaOrder() {
      this.disableBtn = true;
      var fechaString = this.format_date(this.fecha);

      var obj = { comment: this.comment, fecha: fechaString };
      console.log(obj);
      this.createOrder(obj);
    }, */
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
